<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Consulte as vidas dos titulares da empresa">
      <template v-slot:form>
        <v-row dense>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6">
            <v-text-field
              id="name"
              name="name"
              label="Nome do titular ou dependente"
              clearable
              hide-details
              dense
              outlined
              v-model="filter.name"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <v-text-field
              id="document"
              name="document"
              dense
              outlined
              hide-details
              clearable
              label="CPF"
              v-mask="'###.###.###-##'"
              v-model="filter.document"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <BaseMovementSituation
              id="situation"
              name="situation"
              label="Status"
              clearable
              hide-details
              v-model="filter.situation"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="6">
            <BaseButton
              id="btn-search"
              name="btn-search"
              color="primary"
              title="Filtrar"
              icon="fa-solid fa-magnifying-glass"
              height="40"
              @click="search"
            />
          </v-col>
        </v-row>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de vidas dos titulares da empresa">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          show-expand
          single-expand
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template
            v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
          >
            <v-icon
              small
              v-if="!isExpanded && item.dependents.length"
              @click="expand(!isExpanded)"
            >
              fa-solid fa-chevron-down
            </v-icon>
            <v-icon
              small
              v-if="isExpanded && item.dependents.length"
              @click="expand(!isExpanded)"
            >
              fa-solid fa-chevron-up
            </v-icon>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span :title="item.name">
              {{ doTruncateText(item.name, 25) }}
            </span>
          </template>

          <template v-slot:[`item.document`]="{ item }">
            {{ formatCpf(item.document) }}
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  small
                  class="mr-2"
                  @click="edit(item.id, null)"
                >
                  fa-solid fa-right-left
                </v-icon>
              </template>
              <span>Realizar movimentações</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary" small @click="view(item)">
                  fa-regular fa-eye
                </v-icon>
              </template>
              <span>Visualização rápida</span>
            </v-tooltip>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0">
              <v-simple-table style="border: none !important" dense>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(child, index) in item.dependents"
                      :key="index"
                      class="v-data-table__expanded__row"
                    >
                      <td class="text-start" width="10%"></td>
                      <td class="text-start" width="30%" :title="child.name">
                        {{ doTruncateText(child.name, 25) }}
                      </td>
                      <td class="text-start" width="15%">
                        {{ child.kinship }}
                      </td>
                      <td class="text-start" width="15%">
                        {{ formatCpf(child.document) }}
                      </td>
                      <td
                        class="text-start"
                        width="20%"
                        :class="getColor(child.situation)"
                      >
                        {{ child.situation }}
                      </td>
                      <td class="text-start" width="15%">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="success"
                              small
                              class="mr-2"
                              @click="edit(item.id, child.id)"
                            >
                              fa-solid fa-right-left
                            </v-icon>
                          </template>
                          <span>Realizar movimentações</span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="primary"
                              small
                              @click="view(child)"
                            >
                              fa-regular fa-eye
                            </v-icon>
                          </template>
                          <span>Visualização rápida</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <DialogResume
      v-if="dialog"
      :dialog="dialog"
      :model="selectItem"
      @closeModal="closeModal($event)"
    />
  </v-container>
</template>

<script>
import { formatCpf } from '@/helpers/formatting';
import { getColor, doTruncateText, unmask } from '@/helpers/utils';
import { MovementService } from '@/services/api/movements';

export default {
  components: {
    DialogResume: () =>
      import('@/components/pages/movements/dialogs/dialog-resume')
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    options: {},
    dialog: false,
    selectItem: {},
    filter: {
      document: null,
      name: null,
      situation: null
    },
    headers: [
      { text: '', value: 'data-table-expand', width: '10%' },
      { text: 'Nome', value: 'name', width: '30%' },
      { text: 'Parentesco', value: 'kinship', width: '15%' },
      { text: 'CPF', value: 'document', width: '15%' },
      { text: 'Status', value: 'situation', width: '20%' },
      { text: '', value: 'action', width: '10%' }
    ]
  }),

  methods: {
    formatCpf,
    getColor,
    doTruncateText,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const { name, document, situation } = this.filter;
        const { customerId } = this.$route.params;

        const movementService = new MovementService();
        const { status, data } = await movementService.getMovements({
          page,
          take: itemsPerPage,
          name,
          document: unmask(document),
          situation,
          customerId
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      const { customerId } = this.$route.params;
      this.$router.push({ name: 'movimentacoes-new', params: { customerId } });
    },

    view(item) {
      this.dialog = true;
      this.selectItem = item;
    },

    edit(employeeId, dependentId) {
      this.$store.commit('movements/clearCardPage');

      const { customerId } = this.$route.params;

      const params = dependentId
        ? { customerId, employeeId, employeeOrDependentId: dependentId }
        : { customerId, employeeId, employeeOrDependentId: employeeId };

      this.$router.push({
        name: 'movimentacoes-edit',
        params
      });
    },

    closeModal() {
      this.dialog = false;
      this.selectItem = {};
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
